import Reviews from "@/components/home/reviews/Reviews.vue";
import Form from "@/components/form/Form.vue";
import LatestWork from "@/components/works/latest-work/LatestWork.vue";
import ScrollToBtn from '@/components/common/scrollto/ScrollToBtn.vue'
import api from '@/api/about'

export default {
  metaInfo: {
    title: 'About – ImmerseFX'
  },
  components: {Reviews, Form, LatestWork, ScrollToBtn},
  data() {
    return {
      data: null
    }
  },
  created() {
    api.get(this)
      .then(res => {
        if (res.data.length) {
          this.data = res.data[0]
        }
      })
  },
  computed:{
    images() {
      if (this.data?.images) {
        if (typeof this.data.images === 'string') {
          return JSON.parse(this.data.images)
        }
        return this.data.images
      }
      return []
    }
  }
}
