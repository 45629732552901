import VueSlickCarousel from 'vue-slick-carousel'
import api from '@/api/testimonials'

export default {
	name: 'Reviews',
	components: {
		VueSlickCarousel
	},
	data() {
		return {
			settings: {
				arrows: true,
				dots: true,
			},
		}
	},
	created() {
		api.getAll(this)
	},
	computed: {
		dataList () {
			if (this.state?.testimonials) {
				return this.state.testimonials
			}
			return []
		},
		width() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return 355
				case 'sm': return 436
				case 'md': return 436
				case 'lg': return 436
				case 'xl': return 436
			}
		}
	}
}
