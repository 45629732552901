export default {
  data () {
    return {
      dialog: false
    }
  },
  mounted () {
    this.$root.$on('SuccessModal', (dialog) => {
      this.dialog = dialog
    })
  }
}
