import getProjects from "@/api/projects";

export default {
	name: 'LatestWork',
	data() {
		return {}
	},
	computed: {
		projects() {
			if (this.state.projects) {
				return this.state.projects.data
			}
			return []
		}
	},
	created() {
		getProjects.getAll(this)
	},
}
