import api from '@/api/form'
import {notify} from "@/helpers/notifyHelper";
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'

export default {
  data: () => ({
    valid: true,
    form: {
      email: '',
      name: '',
      text: ''
    },
    nameRules: [
      v => !!v || 'Name is required',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    textareaRules: [
      v => !!v || 'Textarea is required',
    ],
  }),
  methods: {
    submit(e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        api.create(this, this.form)
          .then(() => {
            // send gtag
            this.$gtag.event(
              'conversion',
              {send_to: `${process.env.VUE_APP_GTAG_ID}/${process.env.VUE_APP_GTAG_SEND_TO}`}
            )
            notify(this, 'success', "We will contact you soon")
            this.$refs.form.reset()
            this.$router.push({name: 'ThankYou'})
            //
            // this.$root.$emit('SuccessModal', true)
          })
      }
    },
  },
  computed: {
    currentRouter() {
      return this.$route.name
    }
  },
  components: {
    ConfirmModal
  }
}
