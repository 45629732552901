import ApiBase from '@/api/base'
import {notify} from "@/helpers/notifyHelper"

class About {
	static get (self, ) {
		return self.http('get', `${ApiBase.baseUrl()}/about/get`)
	}

	static update (self, id, data) {
		return self.http('put', `${ApiBase.baseUrl()}/about/${id}`, data)
			.then(response => {
				notify(self, 'success', response.data.message)
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}
}

export default About
