import ApiBase from '@/api/base'
import {notify} from "@/helpers/notifyHelper";

class Form {
	static create (self, form) {
		return self.http('post', `${ApiBase.baseUrl()}/contact-submittings/create`, form)
	}

	static getPagination (self, offset, limit) {
		self.http('get', `${ApiBase.baseUrl()}/contact-submittings/list/offset/${offset}/limit/${limit}`)
			.then(response => {
				ApiBase.setToStore(null, 'setContactSubmittingList', response.data)
			})
	}

	static delete (self, id) {
		self.http('delete', `${ApiBase.baseUrl()}/contact-submittings/delete/${id}`)
			.then(response => {
				notify(self, 'success', response.data.message)
				self.$root.$emit('ConfirmingModal', false)
				this.getPagination(self, 0, 10)
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}
}

export default Form
